body {
  margin: 0;
  padding: 0;
  font-family: "Teachers", sans-serif;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
input,
a,
textarea {
  font-family: "Teachers", sans-serif !important;
}

html {
  margin: 0;
  padding: 0;
}

.constact-us-textarea::placeholder {
  color: #0000005c;
  outline: none;
}

.constact-us-textarea:focus-visible {
  outline: none;
}

* {
  scroll-behavior: smooth;
}